/* @import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700&display=swap");

:root {
  --bg: hsl(0 0% 5%);
  --white: hsl(0 0% 90%);
  --accent: hsl(0 0% 10%);
  --highlight: #03dac6;
  --swiper-navigation-color: #000000;
  --swiper-navigation-size: 1.3em;
}

body {
  background: #F7F7FF;
}

.wrapper {
  width: 100% !important;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
}

.text {
  display: flex;
  justify-content: start;
  flex-direction: column;
}

.swiper {
  height: 140px !important;
}

.swiper-slide {
  position: relative;
  width: 90px !important;
  height: 100px !important;
  aspect-ratio: 3/4;
  cursor: pointer;
  border-radius: 10px;

  .swiper-overlay {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100px;
    background: #000;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: inherit;
    object-fit: cover;
    object-position: center;
  }

  .swiper-description {
    position: absolute;
    bottom: 0;
    padding: 0.5em;
    color: var(--white);
    transform: translateY(100vh);
    transition: transform 0.3s ease;

    h1 {
      font-size: 1.5rem;
    }

    p {
      font-size: 0.7rem;
      margin-top: 0.5em;
    }
  }
}

.swiper-slide:hover {
  .swiper-overlay {
    opacity: 0.5;
  }

  .swiper-description {
    transform: translateY(0);
  }
}

.swiper-button-next,
.swiper-button-prev {
  background: rgba(255, 255, 255, 40%);
  border-radius: 100%;
  padding: 1em;
  width: 0.5em;
  height: 0.5em;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}

.swiper-button-next {
  right: 0;
}

.swiper-button-prev {
  left: 0;
}

.card4 {
  border-radius: 0px !important;
  box-shadow: none !important;
  border: none !important;
}

.card6{
  border-radius: 0px !important;
  box-shadow: none !important;
  border: none !important;
  padding-bottom: 8px;
  z-index: 0;
}

p8 {
  font-size: 10px;
  display: flex;
  justify-content: start;
} */

/* Optional: Add custom styles for your carousel */
.swiper-container {
  width: 100%;
  /* Adjust the width of the carousel */
  padding: 16px;
  /* Optional padding */
}

.swiper-slide {
  width: 100px;
  /* Set the width of each slide */
  /* Additional styling for each slide */
}

/* MyCarousel.css */
.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 30px;
  height: 30px;
  background-color: rgb(91, 90, 90);
  /* Set background color of arrows to grey */
  border-radius: 50px;
  color: white;
  /* Set color of arrow icons to white */
}

.carousel-control-next,
.carousel-control-prev {
  width: 15%;
}

@media only screen and (max-width: 600px) {

  .carousel-control-next,
  .carousel-control-prev {
    /* width: 15%; */
  }
}

.card-title {
  font-size: smaller;
}

.card-body {
  padding: 1px;
}

.card-subtitle {
  font-size: x-small;
}

.card-text {
  font-size: x-small;
}

.suggetioncard {
  width: 80px;
  height: auto;
  margin: 5px;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12) !important;
  border-radius: 10px !important;
}

.card-img-top {
  object-fit: cover;
  width: 130px;
  height: auto;
  border-radius: 10px;
}

@media only screen and (max-width: 1550px) {
  .card-img-top {
    width: 80px !important;
    height: auto;
  }
}

@media only screen and (min-width: 1550px) {
  .card-img-top {
    width: 130px !important;
    height: auto;
  }

  .suggetioncard {
    width: 130px !important;
  }
}