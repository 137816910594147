.alignLeft {
  display: flex;
  justify-content: start;
}

.itemShape {
  border-radius: 20px !important;
  padding: 10px !important;
}

.zeropadding {
  padding: 0px !important;
}

.itemShape2 {
  min-height: 85px;
  border-radius: 20px !important;
}

.accordata {
  padding-top: 0px !important;
}

.datafieldsacc {
  display: flex;
  align-items: center;
  justify-content: start;
}

.searchContent {
  margin: 20px;
  padding: 20px;
  border-radius: 20px !important;
  background-color: #ccbdd4 !important;
}

@media screen and (max-width: 600px) {
  .living-in {
    width: 240px !important;
  }
}

@media screen and (max-width: 900px) and (min-width: 601px) {
  .living-in {
    width: 440px !important;
  }
}