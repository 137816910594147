.SSO-Google,
.SSO-Facebook,
.SSO-Microsoft {
  width: 100%;
  /* Set a fixed width for all buttons */
  height: 40px;
  display: flex;
  flex-direction: row;
  margin-left: -4px;
  align-items: center !important;
  background-color: #ffffff;
  color: Black;
  cursor: pointer;
  border-radius: 8px;
  border-width: 2px;
  border: 2px solid #e3e3e3;
  font-size: 16px;
  font-weight: 300;
}

.SSO-Google {
  margin-top: 20px;
}

.SSO-Google:hover {
  background-color: #b83b34;
  color: white;
  /* background: linear-gradient(to bottom, #d9453e, #f7f02e, #2eb531, #378ce0); Adjust the gradient colors for hover state */
}

.SSO-Facebook:hover {
  background-color: #557fd9;
  color: white;
}

.SSO-Microsoft:hover {
  background-color: #d68a18;
  color: white;
}

.social {
  width: 30px;
  height: 30px;
}

.social-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  align-items: flex-start;
  /* Align buttons and content to the left */
  margin-left: 10px;
}

.SSO-Google img,
.SSO-Facebook img,
.SSO-Microsoft img {
  width: 1em;
  height: auto;
  margin-right: 5px;
  /* Adjust the margin as needed to separate image and text */
  margin-left: 25%;
}

.button-text {
  margin-left: 1vw;
}

