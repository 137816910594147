ul.a {
  list-style-type: circle;
  padding-left: 8px;
}

ul.b {
  list-style-type: square;
  line-height: 20px;
  padding-left: 8px;
}

li {
  margin: 12px;
}