body {
  font-family: 'DM Sans';
}

.OtherProfile {
  margin-top: 40px;
  width: 100%;
  min-height: 100vh;
}

.otherproimg {
  height: 330px;
}

.othermini {
  width: 80px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.miniImgsWeb {
  display: flex;
  flex-direction: column;
}

.miniImgsMobile {
  display: flex;
  /* flex-direction: row !important; */
  justify-content: center;
}

.css-cuefkz-MuiContainer-root {
  padding: 10px !important;
}

.data {
  display: flex;
  flex-direction: column;
}

p1 {
  font-size: 14px;
  display: flex;
  justify-content: start;
  font-family: 'DM Sans';
}

p2 {
  text-align: justify;
  font-family: 'DM Sans';
}

.sides {
  margin: 40px;
}

@media only screen and (max-width: 600px) {
  .sides {
    margin: 15px;
  }

  .horasimg {
    width: 150px;
  }

  .ant-upload-select {
    height: 80px !important;
    width: 80px !important;
  }

  .uploadbtn {
    font-size: smaller;
  }

  .uploadbtntext {
    margin-top: 2px !important;
  }
}

.uploadbtntext {
  margin-top: 8px;
}

p3 {
  font-size: 14px;
  font-family: 'DM Sans';
}

.firstdata {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.seconddata {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.thirddata {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

h3 {
  font-family: 'DM Sans';
}

h5 {
  font-family: 'DM Sans';
  margin-top: 10px;
  font-weight: 600;
}

h6 {
  font-family: 'DM Sans';
}

.chip {
  font-family: 'DM Sans';
  margin-right: 10px;
}

.horasimg {
  margin-right: 20px;
}

.editIcon {
  display: flex;
  justify-content: end;
}

.topspaceprof {
  margin-top: 60px !important;
}

.editmodal {
  display: flex;
  justify-content: end;
  gap: 10px;
  margin: 10px 0px 10px 0px;
}

.topicprofile {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.topicprofileContent{
  font-size: 18px;
  font-weight: 600;
}

.btn1 {
  display: flex;
  justify-content: end;
}

@media only screen and (min-width: 600px) and (max-width: 1150px) {
  .imageprivacy {
    display: flex;
    flex-direction: column;
  }
}

.arrow {
  width: 15px !important;
}

.slick-slider{
  flex-direction: row;
}

@media only screen and (max-width: 1123px) {
  .reqpts {
    height: 35px !important;
  }
}

@media only screen and (min-width: 1150px) {
  .row1{
    display: flex;
  }
}
