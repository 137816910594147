.topic {
  display: flex;
  justify-content: center;
}

.cardContent {
  width: 60vw;
}

.regbuttonarea {
  display: flex;
  justify-content: end;
  align-items: end;
  display: flex;
  align-items: center;
  margin: 10px;
}

.regbutton {
  background-color: #9E80BC;
  height: 40px;
  color: white;
  border-radius: 15px;
  margin: 5px;
}

@media screen and (max-width: 600px) {
  .cardContent {
    width: 100% !important;
  }
}

@media screen and (max-width: 280px) {
  .form-control {
    /* width: 180px !important; */
  }
}