p {
  margin: 2px;
  font-size: 12px;
}


body {
  background: #F7F7FF !important;
}

.topspaceres {
  margin-top: 60px !important;
}

@media screen and (min-width: 900px) {
  .filter-icon {
    display: none;
  }

  .cardimage {
    width: 100px;
    height: 100px;
    border-radius: 10px;
  }
}


@media screen and (max-width: 1380px) {
  .rcard {
    height: 120px !important;
  }
}

@media screen and (min-width: 900px) and (max-width: 1080px) {
  .morebut {
    width: 12px !important;
  }
}

.resultcards {
  margin: 5px;
  border-radius: 15px !important;
  padding: 0px;
}

.cardimage {
  width: 100%;
  height: auto;
  border-radius: 15px;
}

.pagination {
  display: flex !important;
  justify-content: center !important;
  margin-top: 10px !important;
  margin-bottom: 50px !important;
}

.onefilter {
  margin-bottom: 10px;
}

.filteraccordion {
  border: none !important;
  box-shadow: none !important;
  border-radius: 30px !important;
}

.borderclr {
  border: 2px solid red !important;
}

.acordion_items {
  font-family: 'DM Sans' !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.radio {
  display: flex;
  align-items: center;
  height: 30px;
}

.radioGroup{
margin-top: 10px;
}

.radiobtns{
  padding: 16px;
}

.ant-modal-content {
  background-color: rgb(244, 237, 250) !important;
}

.eee {
  border: 1px solid red !important;
}

.ant-modal-close{
  top:5px !important;
  right: 5px !important;
}