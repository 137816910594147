.Cont {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

@media only screen and (max-width: 400px) {
  .detailsfot {
    font-size: 12px !important;
  }
}

@media only screen and (min-width: 600px) {
  .leftCont {
    margin-left: 5vw;
  }
}

@media only screen and (max-width: 800px) {
  .Cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

@media only screen and (min-width: 768px) {
  .footerWebAlignemnts {
    display: flex;
    justify-content: center;
  }
}
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}


.MuiChartsLegend-root {
  display: none;
}

.MuiChartsLegend-series {
  display: none;
}

.iconbuttonphone {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 15px;
  margin: 10px 10px 10px 0px;
}

.css-ati914-MuiTypography-root {
  font-size: 0.775rem;
}

.powered {
  font-family: 'Dm Sans';
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
  font-size: 12px;
  /* background-color: #F7F7FF; */
  color: rgb(0, 0, 0);
}

.pointer {
  cursor: pointer;
}

.social-icons {
  display: flex;
  gap: 8px;
}

a{
  color: #000000 !important;
}

.no-underline {
  text-decoration: none !important;
  /* Removes underline */
}