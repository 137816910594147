.verify-email-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  margin-top: 5%;
  margin-bottom: 15vh;
}

.verify-email-content {
  text-align: center;
  width: 50%;
  padding: 2rem;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

@media only screen and (max-width: 600px) {
  .verify-email-content {
    width: 80%;
  }
}

.verify-email-header {
  margin-bottom: 2rem;
}

.verify-email-header h1 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #3f51b5;
}

.verify-email-body {
  margin-top: 2rem;
}

.verify-email-body h2 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #000000;
}

.verify-email-body p {
  font-size: 1rem;
  color: #333;
  margin-bottom: 2rem;
}

.verify-email-body strong {
  color: #000000;
}

.verify-email-body button {
  padding: 0.5rem 2rem;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.verify-email-body button:hover {
  background-color: #c9dda8;
  color: white;
}