.contact-topic {
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-label {
    color: #606060;
}

.contact-textbox {
    height: 45px;
    margin-bottom: 10px;
}

.contact-btn {
    height: 48px;
    width: 200px;
}

.socialmedia-icons {
    color: var(--btn-primary-color);
}

.contact-topic-2 {
    font-weight: 700;
    text-transform: lowercase;
    /* Convert the whole text to lowercase */
    /* font-family: 'DM Sans' !important; */
}

.contact-topic-2::first-letter {
    text-transform: uppercase;
    /* Capitalize only the first letter */
}

.contact-paragraph {
    color: #606060;
    margin: 5px;
    font-size: 12px !important;
    text-align: justify;
}

/*desktops - higher resolution*/
@media (min-width: 1281px) {
    .contact-topic-2 {
        font-size: 30px;
    }

    .contact-topic {
        font-size: 30px;
    }
}

@media (min-width: 1024px) and (min-height: 1366px) {
    .contact-container {
        height: 935px;
    }
}

/*laptops, desktops*/
@media (min-width: 1025px) and (max-width: 1280px) {
    .contact-topic-2 {
        font-size: 30px;
    }

    .contact-topic {
        font-size: 30px;
    }
}

/*tablets, ipads (portrait)*/
@media (min-width: 768px) and (max-width: 1024px) {
    .contact-topic-2 {
        font-size: 28px;
    }

    .contact-topic {
        font-size: 28px;
    }
}

/*tablets, ipads (landscape)*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .contact-topic-2 {
        font-size: 28px;
    }

    .contact-topic {
        font-size: 28px;
    }
}

/*low resolution tablets, mobiles (landscape)*/
@media (min-width: 481px) and (max-width: 767px) {
    .contact-topic-2 {
        font-size: 26px;
    }

    .contact-topic {
        font-size: 26px;
    }
}

/*smartphones mobiles (portrait)*/
@media (min-width: 320px) and (max-width: 480px) {
    .contact-topic-2 {
        font-size: 22px;
    }

    .contact-topic {
        font-size: 22px;
    }
}

.cover-image-container {
    position: relative;
    width: 100%;
    height: 30vh;
    /* 30% of the viewport height */
    overflow: hidden;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Dark-colored overlay with 50% opacity */
}

.cover-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(50%);
    /* Adjust brightness for a darker effect */
}

.cover-text {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    z-index: 1;
    /* Ensure the text is above the overlay */
}

h4 {
    font-weight: 600;
    margin: 20px 20px 0px 0px;
}