
body {
  margin: 0;
}

h1,
h4 {
  margin: 0;
}

.logo {
  width: 188px;
}

.landingContent {
  display: flex;
  flex-direction: column;
}

.leftside {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.leftside {
  height: 100vh;
}

.landtext {
  padding-bottom: 20px;
  text-shadow:
    -1px -1px 0 #575757,
    1px -1px 0 #575757,
    -1px 1px 0 #575757,
    1px 1px 0 #575757;
}

.landtext1 {
  color: #ffffff;
  font-family: 'DM Sans';
  font-size: 30px;
}

.landtext2 {
  color: #ffffff;
  font-family: 'DM Serif Display', serif;
  font-size: 64px;
  line-height: 65px;
  letter-spacing: normal;
}

.landtext3 {
  color: #ffffff;
  font-family: 'DM Sans';
  font-size: 30px;
}

.startedButtonarea {
  margin-bottom: 40px;
}

.startedButton {
  background: linear-gradient(to bottom, #FFA734, #FF7D40);
  border: none;
  color: #fff;
  border-radius: 30px !important;
  cursor: pointer;
  padding: 10px 10px 10px 10px !important;
  font-family: 'DM Sans';
  display: flex;
  justify-content: center;
  align-items: center;
}

.startedButton2 {
  background: linear-gradient(to bottom, #FFA734, #FF7D40);
  border: none;
  color: #fff;
  border-radius: 10px !important;
  cursor: pointer;
  padding: 5px 10px 5px 10px !important;
  font-family: 'DM Sans';
}

.welcome-srcn-logo{
width: 300px;
}

@media only screen and (max-width: 600px) {
  .leftside {
    padding: 0px 30px 50px 30px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .landtext1 {
    font-size: 30px;
  }

  .landtext2 {
    font-size: 32px !important;
    line-height: 32px;
    text-align: center;
  }

  .landtext3 {
    font-size: 16px;
  }

  .lines {
    margin: 10px 10px 2px 20px !important;
  }

  .powered {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .svg {
    width: 45px !important;
    height: 45px !important;
  }

  .imgesoflist {
    width: 100px !important;
    margin: 15px !important;
  }

  .welcome-srcn-logo{
    width: 240px;
    }
    
}

@media only screen and (max-width: 400px) {
  .powered {
    font-size: x-small;
  }

  .iconbutton {
    font-size: 10px !important;
  }

  .svg {
    width: 32px !important;
    height: 32px !important;
  }

  .imgesoflist {
    width: 60px;
    margin: 10px !important;
  }

  .detailsfot {
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 360px) {
  .imgesoflist {
    width: 60px !important;
    margin: 5px !important;
  }
}

@media only screen and (max-width: 1250px) {
  .p6 {
    margin: 30px 20px 20px 50px !important;
  }

  .p7 {
    margin: 40px 20px 10px 50px !important;
  }

  .imgesoflist {
    width: 250px;
    margin: 30px;
  }
}

@media only screen and (max-width: 1000px) {
  .p6 {
    margin: 15px 10px 15px 30px !important;
  }

  .p7 {
    margin: 25px 10px 5px 30px !important;
  }
}

.p6 {
  font-size: 16px;
  text-align: center;
  margin: 50px 10px 20px 120px;
}

.p7 {
  font-size: 16px;
  text-align: center;
  margin: 20px 10px 50px 120px;
}

@media only screen and (max-width: 1100px) {
  .couplephoto {
    width: 400px !important;
  }
}

@media only screen and (max-width: 1000px) {
  .couplephoto {
    width: 350px !important;
  }

  .imgesoflist {
    width: 150px;
    margin: 20px;
  }
}

.subtopic1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 40px;
}

.about_right {
  display: flex;
  justify-content: center;
}

.imgList {
  display: flex;
  justify-content: center;
  margin: 30px 30px 17px 30px;
}

.video {
  display: flex;
  justify-content: center;
}

.iconbutton {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 20px;
  margin: 10px 10px 10px 0px;
}

.iconbuttonphone {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 15px;
  margin: 10px 10px 10px 0px;
}

.css-ati914-MuiTypography-root {
  font-size: 0.775rem;
}

.lines {
  margin: 15px 60px 10px 60px;
}

.lines2 {
  flex-direction: column;
}

.rightFoot {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.svg {
  width: 64px;
  height: 64px;
}

@media only screen and (min-width: 1250px) {
  .imgesoflist {
    width: 300px;
    margin: 40px;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1100px) {
  .profilename {
    font-size: 12px;
  }

  .cancelbtn {
    font-size: 8px !important;
    min-width: 40px !important;
    padding: 1px !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1000px) {
  .profilename {
    font-size: 10px;
  }

  .cancelbtn {
    font-size: 8px !important;
    min-width: 40px !important;
    padding: 1px !important;
  }
}

.proname {
  font-size: x-large;
}

.alignRight {
  display: flex;
  justify-content: end;
}

.social-icons {
  display: flex;
  gap: 8px;
}

a{
  color: #FFFFFF !important;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  background-color: #f0f4f8;
}

.welcome-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  background-color: #e8f6f3;
  padding: 20px;
  align-items: center;
}

.welcome-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: auto;
}

.welcome-title {
  font-weight: bold;
  color: #2e3b55;
}

.welcome-subtitle {
  color: #5a6a85;
  margin-top: 8px;
}

.progress-bar {
  width: 150px;
  height: 5px;
  background: linear-gradient(to right, #ffa600, #00ff0d);
  margin: 15px auto;
  border-radius: 5px;
}

.welcome-card {
  margin-top: 20px;
  padding: 0 0 16px 0;
  border-radius: 4px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.welcome-image {
  border-radius: 4px;
  margin-bottom: 15px;
}

.sign-in-button {
  /* background-color: #348b37 !important; */
  color: white;
  margin: 15px auto;
  text-transform: none;
  font-size: 16px;
}

.sign-in-button:hover {
  box-shadow: 0 12px 16px 0 rgba(221, 138, 43, 0.24),0 17px 50px 0 rgba(211, 133, 31, 0.19) !important;
}

.sign-up-text {
  margin-top: 10px;
  color: #5a6a85;
}

.link {
  color: #007bff;
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}

.footer {
  text-align: center;
  font-size: 12px;
  color: #7d8da4;
  margin-top: 20px;
}