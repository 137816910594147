.totoalProfileData {
  display: flex;
}

@media screen and (min-width: 600px) {
  .appbarMenuicon {
    display: none !important;
  }
}

@media screen and (max-width: 600px) {
  .appbarWebMenuicon {
    display: none !important;
  }
}

.appBar {
  display: flex;
  justify-content: space-between;
}

.appbarcontent {
  display: flex;
  gap: 10px;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .sideBar {
    display: none !important;
  }
}

.profiledata {
  display: flex;
  flex-direction: column;

  align-items: center;
}

p {
  font-size: 10px !important;
}

p5 {
  font-family: 16px;
}

.Listclass {
  padding-left: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-right: 0px !important;
}

.sidebarbaisc {
  display: flex;
  justify-content: center;
}

.css-10hburv-MuiTypography-root {
  font-family: 'DM Sans', sans-serif !important;
}