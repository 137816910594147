div .dmsans {
  font-family: 'DM Sans';
}


.filter {
  display: flex;
  flex-direction: row;
}

.age {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.height {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.religion {
  display: flex !important;
  flex-direction: row !important;
}

.card {
  border-radius: 20px !important;
  box-shadow: none !important;
  border: none !important;
}

.card1 {
  height: 100px !important;
  background-color: #ccbdd4 !important;
}

.card7 {
  height: 180px !important;
  width: 250px;
}

@media only screen and (max-width: 1250px) and (min-width:601px) {
  .card1 {
    height: 200px !important;
  }
}

@media only screen and (max-width: 600px) {
  .card1 {
    height: 200px !important;
  }

  .dashboard {
    margin-top: 50px;
  }

  .profileimg {
    display: none;
  }

  .age {
    font-size: 12px;
    display: flex;
    align-items: center;
  }

  .dropdown {
    width: '40px' !important;
    height: '20px' !important;
  }

  .purplebutton {
    height: 40px;
    width: 60px;
  }

  .purplebuttonmain {
    height: 30px;
    width: 100px !important;
    font-size: 12px !important;
  }

  .bottomspacedash {
    margin-bottom: 100px !important;
  }

  .imgreq {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 400px) {
  .card1 {
    height: 200px !important;
  }
}

.purplebutton {
  height: 30px;
  width: 50px;
}

.filter {
  height: 300px;
}

.filterItems {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.h6tag {
  display: flex;
  justify-content: center;
}

.card2 {
  height: 250px !important;
  padding: 10px;
}

.card4 {
  height: 100px !important;
  padding: 10px;
}

.card5 {
  height: 100px !important;
  padding: 5px;
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
  .card5 {
    height: 250px !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1250px) {
  .card5 {
    height: 200px !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1030px) {
  .reqbut {
    padding: 2px !important;
  }
}

.card3 {
  padding: 10px;
  height: 200px !important;
}

.cardimg {
  border-radius: 10px !important;
  box-shadow: none !important;
  border: none !important;
}

.dashboard {
  padding: 20px;
  min-height: 100vh;
}

.tabpanel {
  padding: 5px !important;
}

.imageSlider {
  display: flex;
  justify-content: center;
}

.topspacedash {
  margin-top: 40px !important;
}

.bottomspacedash {
  margin-bottom: 40px;
}

@media only screen and (max-width: 500px) {
  .card1 {
    height: 300px !important;
  }
}

@media only screen and (max-width: 430px) {
  .btmspacedash-2 {
    margin-bottom: 10px !important;
  }

  .card3 {
    height: 220px !important;
  }
}

p4 {
  font-size: 14px;
}

.strokeme {
  /* color: white;   */
  /* text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000; */
}

.hidden_profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px;
}

.reqbutton {
  font-size: 10px !important;
}

.whitefont {
  color: aliceblue !important;
}

.signIn{
  margin: 5px;
  font-size: 15px;
  font-weight: 600;
  background-image: linear-gradient(to bottom, #ecd5b6, #e6bb56);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
}

.signIn:hover {
  background-image: linear-gradient(to bottom, #ffffff, #f6e1c9); /* Change to lighter color */
}

@media only screen and (min-width: 900px) and (max-width: 999px) {
  .vivakamlogo {
    width: 120px !important;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1250px) {
  .vivakamlogo {
    width: 150px !important;
  }
}

.vivakamlogo {
  border-radius: 5px;
  margin: 2px;
  width: 200px;
  height: auto;
  object-fit: cover;
}

@media only screen and (max-width: 1550px) {
  .wholedashboard {
    min-height: 100vh;
  }

  .suggetioncard {
    height: 150px !important;
    width: auto !important;
  }
}

@media only screen and (min-width: 1550px) {
  .part1 {
    height: 140px;
  }

  .part2 {
    height: 290px;
  }

  .topspacedash {
    margin-top: 60px !important;
  }

  .wholedashboard {
    height: 85vh;
    min-height: 85vh !important;
  }

  .card3 {
    height: 260px !important;
  }

  .suggetioncard {
    height: 200px !important;
    width: 130px !important;
  }
}

.filterTags{
font-size: 14px !important;
/* font-weight: 600 !important; */
}